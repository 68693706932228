
module controllers {
    export module master {
        interface IFileTemplatUpdateScope extends ng.IScope {
        }

        interface IFileTemplatUpdateScopeParams extends ng.ui.IStateParamsService {
            fileTemplateId: number;
        }

        export class fileTemplateUpdateCtrl {
            static $inject = ["$scope",
                "$rootScope",
                "generalService",
                "$q",
                "bsLoadingOverlayService",
                "$timeout",
                '$uibModal',
                'fileTemplateService',
                'entityService',
                '$state',
                '$stateParams',
                'uiGridConstants',
                '$transitions',
                'classificationValueService',
            ];

            selectedTab: number = 0;
            fileTemplateId: number = 0;
            FileTemplate: interfaces.master.IFileTemplateViewModel;     
            
            columnMapping: interfaces.master.ColumnMapping[] = [];

            fileUploadObject: any;

            breadCrumbDesc: string;

            IsLoading: boolean = false;
            fileTemplateHook: any;

            constructor(private $scope: IFileTemplatUpdateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private fileTemplateService: interfaces.master.IFileTemplateService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $state: ng.ui.IStateService,
                private $stateParams: IFileTemplatUpdateScopeParams,
                private uiGridConstants: uiGrid.IUiGridConstants,
                private $transitions: ng.ui.core.ITransition,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService
            ) {
                if ($stateParams.fileTemplateId == null) {
                    return;
                }
                this.fileTemplateId = $stateParams.fileTemplateId;

                this.fileTemplateHook = $transitions.onSuccess({
                    to: 'auth.MasterData.fileTemplate.Update',
                    from: 'auth.MasterData.fileTemplate.Update.**'
                }, () => {
                    return this.loadfileTemplate();
                });


                this.fileUploadObject = {};

                // Watch for changes in the fileUploadObject
                $scope.$watch(() => this.fileUploadObject, (newValue, oldValue) => {                    

                    // If the fileUploadObject is not empty, then convert the base64 string to a normal string and parse the JSON to an object
                    if (newValue && newValue.base64) {
                        const jsonString = atob(newValue.base64);
                        this.FileTemplate.TemplateDefinition = JSON.parse(jsonString);
                    }
                     
                }, true);  

                

                this.loadfileTemplate()
            }

            loadfileTemplate() {
                this.IsLoading = true;
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'fileTemplate.update'
                },
                    () => {
                        return this.fileTemplateService.getDetail(this.fileTemplateId).query((result: interfaces.master.IFileTemplateViewModel) => {
                            this.IsLoading = false;
                            this.FileTemplate = result;

                            if (this.FileTemplate.TransactionType !== null && this.FileTemplate.TransactionType.Id > 0) {
                                this.GetColumnMappings();
                            }

                            if (this.FileTemplate.Id < 1)
                                this.breadCrumbDesc = 'New File Template';
                            else
                                this.breadCrumbDesc = this.FileTemplate.Description;
                        }
                            , (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                    });
            }

            loadEntities(searchText: string) {
                return this.entityService.getDropdownList(searchText).query(
                    () => {
                    }, (failureData) => {
                    }).$promise;
            }

            public loadTransactionTypes(){
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.FileTemplateTransactionType
                }, (resultList) => {
                       
                }).$promise;
            }

            public loadFileTypes(){
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.FileTemplateFileType
                }, (resultList) => {
                       
                }).$promise;
            }

            loadColumnDelimiters():ng.IPromise<interfaces.applicationcore.IDropdownModel[]> {
                //return an Array of <interfaces.applicationcore.IDropdownModel> with values Tab, Comma, Pipe, return a promise
                let deferred = this.$q.defer<interfaces.applicationcore.IDropdownModel[]>();

                var ddTab = <interfaces.applicationcore.IDropdownModel>{
                    Id:  0,
                    Code: "",
                    Description: "Tab",
                    Display:  "Tab",
                    Selected: false
                }

                var ddComma = <interfaces.applicationcore.IDropdownModel>{
                    Id:  1,
                    Code: "",
                    Description: "Comma",
                    Display:  "Comma",
                    Selected: false
                }


                var ddPipe = <interfaces.applicationcore.IDropdownModel>{
                    Id:  2,
                    Code: "",
                    Description: "Pipe",
                    Display:  "Pipe",
                    Selected: false
                }                
            
                var delimiters : interfaces.applicationcore.IDropdownModel[] = [];
                delimiters.push(ddTab);
                delimiters.push(ddComma);
                delimiters.push(ddPipe);

                deferred.resolve(delimiters);
                return deferred.promise;
            }

            AddSection(){
                this.FileTemplate.TemplateDefinition.Sections.push({                    
                    SectionName: "",
                    SkipRows: 0,
                    NoOfColumns: 1,
                    Mappings: []
                });
            }

            MoveSectionUp(section: interfaces.master.Section, currentIndex: number){
                if(currentIndex > 0){
                    let temp = this.FileTemplate.TemplateDefinition.Sections[currentIndex - 1];
                    this.FileTemplate.TemplateDefinition.Sections[currentIndex - 1] = section;
                    this.FileTemplate.TemplateDefinition.Sections[currentIndex] = temp;
                }
            }

            MoveSectionDown(section: interfaces.master.Section, currentIndex: number){
                if(currentIndex < this.FileTemplate.TemplateDefinition.Sections.length - 1){
                    let temp = this.FileTemplate.TemplateDefinition.Sections[currentIndex + 1];
                    this.FileTemplate.TemplateDefinition.Sections[currentIndex + 1] = section;
                    this.FileTemplate.TemplateDefinition.Sections[currentIndex] = temp;
                }
            }

            loadParentSections(section: interfaces.master.Section) {
                let parentSections = this.FileTemplate.TemplateDefinition.Sections.filter((s) => s.SectionName != section.SectionName);

                return parentSections;   
            }

            AddMapping(section: interfaces.master.Section){
                section.Mappings.push({
                    MappingName: "",
                    ColumnName: "",
                    Identifier: "",
                    Mask: "",
                    IgnoreForUpload: false
                });
            }

            DeleteSection(section: interfaces.master.Section){
                let index = this.FileTemplate.TemplateDefinition.Sections.indexOf(section);
                this.FileTemplate.TemplateDefinition.Sections.splice(index, 1);
            }

            DeleteMapping(section: interfaces.master.Section, mapping: interfaces.master.Mapping){
                let index = section.Mappings.indexOf(mapping);
                section.Mappings.splice(index, 1);
            }

            GetColumnMappings(){
                this.fileTemplateService.getColumnMapping(this.FileTemplate.TransactionType.Id).query({
                },
                (result: ng.resource.IResourceArray<interfaces.master.ColumnMapping>) => {
                    
                    this.columnMapping = result;

                    //sort by FieldName ASC alphabetically
                    this.columnMapping = this.columnMapping.sort((a, b) => a.FieldName.localeCompare(b.FieldName));

                });
            }

            ColumnMappingChanged(mapping: interfaces.master.Mapping, FieldName: string){
                //look up the columnMapping object that matches the FieldName
                let columnMapping = this.columnMapping.find((cm) => cm.FieldName === FieldName);

                mapping.IgnoreForUpload = columnMapping.IgnoreForUpload;
            }

            skipRowsChanged(section: interfaces.master.Section){
               
                if (section.SkipRows > 0){
                    section.NoOfColumns = 0;
                    section.RecordIdentifier = "";
                    section.ParentSection = ""; 
                    section.Mappings = [];
                }
            }

            GetIgnoreForUpload(FieldName: string){
                let columnMapping = this.columnMapping.find((cm) => cm.FieldName === FieldName);
                if (columnMapping == null) {
                    return false;
                }
                return columnMapping.IgnoreForUpload;
            }

            downloadObjectAsJson(){
                const fileName = 'FileTemplate_'+ this.FileTemplate.Code + ".json";
                var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.FileTemplate.TemplateDefinition));
                var downloadAnchorNode = document.createElement('a');
                downloadAnchorNode.setAttribute("href", dataStr);
                downloadAnchorNode.setAttribute("download", fileName);
                document.body.appendChild(downloadAnchorNode); // required for firefox
                downloadAnchorNode.click();
                downloadAnchorNode.remove();
            }

            ValidateFileTemplate(): boolean {
                var errorMessage = "";
                if (this.FileTemplate !== null){
                    //Validate the FileTemplate - Sections
                    if (this.FileTemplate.TemplateDefinition.Sections.length === 0 && !this.FileTemplate.LibraryName){
                        errorMessage +=  "At least one Section is required."+"<br/>";
                    }
                    else{
                        //loop through the sections
                        for (let i = 0; i < this.FileTemplate.TemplateDefinition.Sections.length; i++){
                            let section = this.FileTemplate.TemplateDefinition.Sections[i];
                            //Validate the Section - SectionName
                            if (section.SectionName === ""){
                                errorMessage += "Section "+ (i+1) + " : Section Name is required."+"<br/>";                           
                            }
                            //Validate the Section - SkipRows
                            if (section.SkipRows === null){
                                errorMessage += "Section "+ (i+1) + " : Skip Rows is required."+"<br/>";
                            }
                            //Validate the Section - NoOfColumns
                            if (section.NoOfColumns === null){
                                errorMessage += "Section "+ (i+1)+ " : Number of Columns is required."+"<br/>";
                            }
                            else{
                                //verify NoOfColmns is greater than 0 and Less than 31
                                if (section.NoOfColumns < 0 || section.NoOfColumns > 31){
                                    errorMessage += "Section "+ (i+1) + " : Number of Columns must be between 0 and 31."+"<br/>";
                                }
                            }
                            
                            //Validate the Section - Mappings
                            if (section.Mappings.length === 0 && section.SkipRows === 0){
                                errorMessage += "Section "+ (i+1) + " : At least one Mapping is required, if Skip Rows = 0."+"<br/>";
                            }
                            else{
                                //loop through the mappings
                                for (let j = 0; j < section.Mappings.length; j++){
                                    let mapping = section.Mappings[j];
                                   
                                    //Validate the Mapping - MappingName
                                    if (mapping.MappingName === ""){
                                       
                                        errorMessage += "Section "+ (i+1) + " - Mapping " + (j+1) + " : Mapping Name is required."+"<br/>";
                                    }
                                    //Validate the Mapping - ColumnName
                                    if (mapping.ColumnName === ""){
                                        errorMessage +=  "Section "+ (i+1) + " - Mapping " + (j+1)  + " : Column  is required."+"<br/>";
                                    }
                                    else{
                                        //get the columnMapping object that matches the ColumnName
                                        let columnMapping = this.columnMapping.find((cm) => cm.FieldName === mapping.ColumnName);
                                        //if the columnmapping.Identifier is not empty, then validate the mapping.Identifier
                                        if (columnMapping && columnMapping.Identifier !== ""){

                                            if (mapping.Identifier === ""){
                                                errorMessage +=  "Section "+ (i+1)+ " - Mapping " + (j+1) + " : "+ columnMapping.Identifier + " Identifier is required."+"<br/>";
                                            }
                                        }
                                    }

                                    //Validate the Mapping - Mask
                                    if (mapping.Mask === ""){
                                        errorMessage +=  "Section "+ (i+1)+ " - Mapping " + (j+1) + " : Mask is required."+"<br/>";
                                    }
                                }

                            }

                           
                        }                        
                    }
                }

                if (errorMessage !== ""){                   
                    this.generalService.displayMessage(errorMessage, Enum.EnumMessageType.Error);
                    return false;
                }

                return true;
            }

                   
            SavefileTemplate(): ng.IPromise<boolean> {
                var deferre = this.$q.defer<boolean>();

                if (this.FileTemplate.Id > 0 && !(this.FileTemplate.LibraryName || this.FileTemplate.LibraryType)){
                    if (!this.ValidateFileTemplate()){
                        deferre.resolve(false);
                        return deferre.promise;
                    }
                 }

                this.fileTemplateService.save().save(this.FileTemplate, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data);
                    this.IsLoading = false;
                    this.fileTemplateId = Number(data.ID);

                    
                    if (!this.FileTemplate.LibraryName){
                        let warningMessage = "";
                        //join all the mappings from each section into a new array
                        let allMappings = this.FileTemplate.TemplateDefinition.Sections.map((s) => s.Mappings).reduce((acc, val) => acc.concat(val), []);
                        //Across all section mapping, get the Mapping columns and check if all Mandatory fields in columnMappings have been mapped
                        let mandatoryColumnMappings = this.columnMapping.filter((cm) => cm.Mandatory);
                        let mandatoryColumnNames = mandatoryColumnMappings.map((cm) => cm.FieldName);
                        let missingMandatoryColumnNames = mandatoryColumnNames.filter((mn) => allMappings.find((m) => m.ColumnName === mn) == null);
                        if (missingMandatoryColumnNames.length > 0){
                            warningMessage = "The following mandatory columns are not mapped : " + missingMandatoryColumnNames.join(", ");
                            this.generalService.displayMessage(warningMessage, Enum.EnumMessageType.Warning);
                        }

                        //if TransactionType = 838 or 839 then Selling Price or Selling Value is required for mapping
                        if (this.FileTemplate.TransactionType.Id === 838){
                                                    //check if 'Selling Price' or 'Selling Value' is missing
                                                    let sellingPriceColumnNames = ['Selling Price', 'Selling Value'];
                                                    let missingSellingPriceColumnNames = sellingPriceColumnNames.filter((mn) => allMappings.find((m) => m.ColumnName === mn) == null);
                                                    if (missingSellingPriceColumnNames.length > 1){
                                                        if (warningMessage.length>0){
                                                            warningMessage += "<br/>";
                                                        }
                                                        warningMessage += "Either " + missingSellingPriceColumnNames.join(", ") + " are required for mapping.";
                                                        this.generalService.displayMessage(warningMessage, Enum.EnumMessageType.Warning);
                                                    }
                        }
                    }

                    deferre.resolve(true);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferre.resolve(false);
                });

                return deferre.promise;
            };

            Save() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'fileTemplate.update'
                },
                    () => {
                        return this.SavefileTemplate().then((data: boolean) => {
                            if (data && this.fileTemplateId > 0) {
                                this.loadfileTemplate();
                            }
                        });
                    });
            }

            SaveClose() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'fileTemplate.update'
                },
                    () => {
                        return this.SavefileTemplate().then((data) => {
                            if (data) {
                                this.$state.go("^");
                            } else {

                            }
                        });
                    });
            }

            Close() {
                this.$state.go("^");
            }
        }

        angular.module("app").controller("fileTemplateUpdateCtrl", controllers.master.fileTemplateUpdateCtrl);
    }
}